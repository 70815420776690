// Home.js
import React from 'react';
// import About from './About';
import { Button, Container, Row, Col } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import '../styles/home.css';
import '../styles/home_mobile.css';
import { useCountdown } from '../hooks/useCountdown';
import ring from '../img/icon/rings.png'
import camera from '../img/icon/camera.png'
import glass from '../img/icon/glass.png'
import plate from '../img/icon/plate.png'
import cake from '../img/icon/cake.png'
import { NavLink } from 'react-router-dom';
// import Slider from "react-slick";
// import img from '../../img';



// const Home = () => <h1 className='h_ita'>Home Page</h1>;

function Home() {

    // const now = new Date()
    const [days, hours, minutes, seconds] = useCountdown('june 28, 25 16:00:00 GMT+01');

    const images = require.context('../img/carousel', true);
    const imageList = images.keys().map(image => images(image));

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    // imageList.map((image, index) => (console.log(image)));

    return (
        <>
            <section id='home'>
                <Container>
                    <Row>
                        <h1>Léa & Lalaina</h1>
                    </Row>
                    <Row className='spacer3'></Row>
                    {/* <Row>
                        <h4>/ 28 Juin 2025 /</h4>
                   </Row> */}
                </Container>
            </section>
            <section id="proverbe">
                <Container id='prv'>
                    <Row className="spacer4"></Row>
                    <Row className="text-center">
                        <h1 >
                            "Et maintenant ces trois choses demeurent : la foi, l'espérance et l'amour; mais la plus grande des trois est l'amour."
                        </h1>
                        <h5 className="mt-2 fst-italic text-end">1 Corinthiens 13:13</h5>
                    </Row>

                    <Row></Row>
                    <Row className='spacer2'></Row>
                    <Row id="timer">
                        <Col className='tm-cont bb'><p>{days}</p> Jours<br /><br /></Col>
                        <Col className='tm-cont bb'><p>{hours}</p>  Heures<br /><br /></Col>
                        <Col className='tm-cont bb'><p>{minutes}</p>  Minutes<br /><br /></Col>
                        <Col className='tm-cont'><p>{seconds}</p>   Secondes<br /><br /></Col>
                    </Row>
                </Container>
            </section>
            <section id='about'>
                <Container id='about_filter'>
                    <Row className='spacer2'></Row>
                    <Row className='spacer2'>
                        <Col className='bb'></Col>
                        <Col></Col>
                    </Row>
                    <Row id='prog'>
                        <p>PROGRAMME</p>
                    </Row>
                    <Row className='spacer2'>
                        <Col className='bb'></Col>
                        <Col></Col>
                    </Row>
                    <Row className='about_info'>
                        <Col className='prog_left' >
                            <Row className='spacer1'></Row>
                            <img src={ring} width="50" alt="fireSpot" />
                            <Row>
                                <p><br />16H00</p>
                            </Row>
                            <Row>
                                <p>CÉRÉMONIE RELIGIEUSE</p>
                            </Row>
                            <Row>
                                <p>Domaine de la Grande Canague</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='about_info'>
                        <Col className='prog_left' >
                            <Row className='spacer1'></Row>
                            <img src={camera} width="50" alt="fireSpot" />
                            <Row>
                                <p><br />17H30</p>
                            </Row>
                            <Row>
                                <p>SOURIEZ</p>
                            </Row>
                            <Row>
                                <p>Photos de groupe avec les mariés</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='about_info'>
                        <Col className='prog_left' >
                            <Row className='spacer1'></Row>
                            <img src={glass} width="50" alt="fireSpot" />
                            <Row>
                                <p><br />18H30</p>
                            </Row>
                            <Row>
                                <p>COKTAIL</p>
                            </Row>
                            <Row>
                                <p>Ouverture du vin d'honneur</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='about_info'>
                        <Col className='prog_left' >
                            <Row className='spacer1'></Row>
                            <img src={plate} width="50" alt="fireSpot" />
                            <Row>
                                <p><br />21H30</p>
                            </Row>
                            <Row>
                                <p>REPAS</p>
                            </Row>
                            <Row>
                                <p>Premier dîner des mariés</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='about_info'>
                        <Col className='prog_left' >
                            <Row className='spacer1'></Row>
                            <img src={cake} width="50" alt="fireSpot" />
                            <Row>
                                <p><br />23H30</p>
                            </Row>
                            <Row>
                                <p>WEDDING CAKE</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='spacer2'></Row>
                    <Row className='about_info'>
                        <Button
                            as={NavLink}
                            to="/about"
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.202)",  // Transparent background
                                color: "white",                 // White text color
                                fontSize: "275%",               // Adjust font size
                                padding: "20px 30px",            // Reduced padding to make the button tighter
                                border: "2px solid white",      // Border around the text
                                display: "inline-block",        // This allows the button to adjust to text size
                                textAlign: "center",            // Ensures content is centered
                                // borderRadius: "25px",           // Rounded corners
                                textTransform: "uppercase",     // Uppercase text
                                fontFamily: "Grandiflora One",  // Font style
                                transition: "background-color 0.3s ease-in-out, transform 0.3s ease-in-out", // Animation
                                letterSpacing: "0.1em",         // Slightly spaced letters for readability
                                width: "auto",                  // Automatically adjust width based on content
                                height: "auto",                 // Ensure height fits the content
                                whiteSpace: "nowrap",           // Prevent text from wrapping
                                margin: "0 auto", 
                            }}
                            onMouseOver={(e) => {
                                e.target.style.transform = "scale(1.05)";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.transform = "scale(1)";
                            }}
                        >
                            + d'info
                        </Button>
                    </Row>
                    <Row className='spacer2'></Row>
                </Container>
            </section>
            {/* <section id='question'>
                <Container>
                    <Row className='spacer1'></Row>
                    <Row id='qu_tittle'>
                        <p>QUESTIONS</p>
                    </Row>
                    <Row className='spacer2'></Row>
                    <Row id='qr'>
                        <Col className='qr_col'>
                            <p className='qr_tittle'>COMMENT S'Y RENDRE ?</p>
                            <p className='qr_content'>LE LIEU EST ACCESSIBLE EN VOITURE.
                                L’ADRESSE “LALE - SAINT FÉLIX DE PALLIÈRES” EST DISPONIBLE SUR TOUS LES GPS.</p>
                        </Col>
                        <Col className='qr_col'>
                            <p className='qr_tittle'>QUEL EST LE DRESS CODE ?</p>

                            <p className='qr_content'>PAS DE DRESS CODE. CEPENDANT L’ÉTÉ EST LÀ, LES COULEURS DE SAISON OU LES THÈMES FLEURIS SERONT LES BIENVENUS.</p>
                        </Col>
                        <Col className='qr_col'>
                            <p className='qr_tittle'>ET POUR LE CADEAU ?</p>
                            <p className='qr_content'>RAMENEZ VOTRE BONNE HUMEUR AINSI QU’UNE BONNE BOUTEILLE DE CHAMPAGNE POUR QUE NOUS FÊTIONS ÇA ENSEMBLE.</p>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section id='question'>
                <Container>
                    <Row className='spacer1'></Row>
                    <Row id='qu_tittle'>
                        <p>CONTACT</p>
                    </Row>
                    <Row id='contact_info'>
                        <Col className='contact_col_left'>
                            <p className='contact_tittle'>Léa</p>
                        </Col>
                        <Col className='contact_col_right'>
                            <p className='contact_content'>06 04 67 30 77</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='contact_col_left'>
                            <p className='contact_tittle'>Lalaina</p>
                        </Col>
                        <Col className='contact_col_right'>
                            <p className='contact_content'>06 38 66 71 33</p>
                        </Col>
                    </Row>
                    <Row className='spacer2'></Row>
                </Container>
            </section>
            {/* <section id='carousel'>
                <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                >
                    {imageList.map((image, index) => (

                        <Carousel.Item>
                            <img height={window.innerHeight} key={index} src={image} alt={`image-${index}`} />
                        </Carousel.Item>
                    ))}
                </Carousel>

            </section> */}
            {/* <section id='contact'>
                <Container>
                    <Row className='spacer2'></Row>
                    <Row id='contact_title'>
                        <p>CONTACTEZ-NOUS</p>
                    </Row>
                    <Row id='contact_info'>
                        <Col className='contact_col'>
                            <p className='contact_tittle'>ADRESSE</p>
                            <p className='contact_content'>LALE, 30140 SAINT-FÉLIX DE PALLIÈRES, FRANCE</p>
                        </Col>
                        <Col className='contact_col'>
                            <p className='contact_tittle'>TÉLÉPHONE</p>
                            <p className='contact_content'>+33 6 12 34 56 78</p>
                        </Col>
                        <Col className='contact_col'>
                            <p className='contact_tittle'>EMAIL</p>
                            <p className='contact_content'>contact@example.com</p>
                        </Col>
                    </Row>
                    <Row className='spacer2'></Row>
                </Container>
            </section> */}
        </>
    );
}
export default Home; 