import React, { useEffect, useState, useRef } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Loader } from "@googlemaps/js-api-loader";
import styles from '../styles/Info.module.css';
import { NavLink } from 'react-router-dom';


export default function Info() {
    const [markerLocation] = useState({ lat: 43.33225, lng: 3.10361 });
    const mapRef = useRef(null);

    useEffect(() => {
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            version: "weekly",
            libraries: ["places"],
        });

        loader.load().then(() => {
            if (!window.google?.maps || !mapRef.current) return;

            const map = new window.google.maps.Map(mapRef.current, {
                center: markerLocation,
                zoom: 15,
                mapTypeControl: true,
                streetViewControl: true,
                fullscreenControl: false,
            });

            new window.google.maps.Marker({
                position: markerLocation,
                map,
                title: "Domaine de la grande Canague",
            });
        });
    }, [markerLocation]);

    return (
        <section className={styles.section}>
            <Container className={styles.con}>
                <Row className="my-4">
                    <Col>
                        <Row>
                            <h1 className={styles.heading}>Itinéraire</h1>
                        </Row>
                        <Row className={styles.spacer}></Row>
                        <Row>
                            <p className={styles.paragraph}>
                                Domaine de la grande Canague <br />
                                34310, Montady, Hérault
                            </p>
                        </Row>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col>
                        <div
                            ref={mapRef}
                            style={{ width: "100%", height: "500px", borderRadius: "20px" }}
                        />
                    </Col>
                </Row>
            </Container>

            {/* Hébergements Section */}
            <Container className={styles.con}>
                <Row className="my-4">
                    <Col>
                        <Row>
                            <h1 className={styles.heading}>Hébergements</h1>
                        </Row>
                        <Row className={styles.spacer}></Row>
                        <Row>
                            <p className={styles.paragraph}>
                                Pour votre séjour, vous trouverez des hébergements à proximité du domaine, à Montady (Hérault), sur des plateformes telles que{" "}
                                <a
                                    href="https://www.airbnb.com/s/Montady--France/homes?checkin=2025-06-27&checkout=2025-06-29"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.lk}
                                >
                                    Airbnb
                                </a>
                                ,{" "}
                                <a
                                    href="https://www.booking.com/searchresults.html?ss=Montady%2C+France&checkin_year_month_monthday=2025-06-27&checkout_year_month_monthday=2025-06-29"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.lk}
                                >
                                    Booking.com
                                </a>
                                ,{" "}
                                <a
                                    href="https://www.hotels.com/search.do?resolved-location=CITY%3A1635411%3AUNKNOWN%3AUNKNOWN&destination-id=1635411&q-check-in=2025-06-27&q-check-out=2025-06-29"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.lk}
                                >
                                    Hotels.com
                                </a>
                                , ou{" "}
                                <a
                                    href="https://www.camping-colombiers.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.lk}
                                >
                                    au camping les Peupliers
                                </a>
                                . Nous vous recommandons de réserver vos hébergements à l'avance pour garantir la disponibilité.
                                Il est également possible de venir avec un camion aménagé ou une tente, un terrain dédié est disponible sur le domaine pour vous accueillir.
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {/* Dress Code Section */}
            <Container className={styles.con}>
                <Row className="my-4">
                    <Col>
                        <Row>
                            <h1 className={styles.heading}>Dress Code</h1>
                        </Row>
                        <Row className={styles.spacer}></Row>
                        <Row>
                            <p className={styles.paragraph}>
                                Optez pour une tenue élégante et colorée, façon Garden Party chic : raffinement, touches florales et éclat de couleurs sont les bienvenus !
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {/* Cadeau des Mariés Section */}
            <Container className={styles.con}>
                <Row className="my-4">
                    <Col>
                        <Row>

                            <h1 className={styles.heading}>Cadeau des mariés</h1>
                        </Row>
                        <Row className={styles.spacer}></Row>
                        <Row>
                            <p className={styles.paragraph}>
                                Fidèles à la tradition malagasy, nous privilégions les Tsoandro (enveloppes) afin de réaliser un beau voyage. Toutefois, les cadeaux seront également reçus avec joie.
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className={styles.con}>
                <Row className="my-4">
                    <Button
                        as={NavLink}
                        to="/rsvp"
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.202)",  // Transparent background
                            color: "white",                 // White text color
                            fontSize: "275%",               // Adjust font size
                            padding: "20px 30px",            // Reduced padding to make the button tighter
                            border: "2px solid white",      // Border around the text
                            display: "inline-block",        // This allows the button to adjust to text size
                            textAlign: "center",            // Ensures content is centered
                            // borderRadius: "25px",           // Rounded corners
                            textTransform: "uppercase",     // Uppercase text
                            fontFamily: "Grandiflora One",  // Font style
                            transition: "background-color 0.3s ease-in-out, transform 0.3s ease-in-out", // Animation
                            letterSpacing: "0.1em",         // Slightly spaced letters for readability
                            width: "auto",                  // Automatically adjust width based on content
                            height: "auto",                 // Ensure height fits the content
                            whiteSpace: "nowrap",           // Prevent text from wrapping
                            margin: "0 auto", 
                        }}
                        onMouseOver={(e) => {
                            e.target.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                            e.target.style.transform = "scale(1)";
                        }}
                    >
                        RSVP
                    </Button>
                </Row>
            </Container>
        </section>
    );
}
