import React, { useState } from "react";
import { FloatingLabel, Form, Button, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/RSVP.css";

function MultiStepForm() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mainGuestComment, setMainGuestComment] = useState("");
    const [guests, setGuests] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validateStep1 = () => {
        if (!email || !phoneNumber || !firstName || !lastName) {
            setError("Tous les champs doivent être remplis.");
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^(?:\+33\s?|0)(6|7)\d{8}$/;
        if (!emailRegex.test(email)) {
            setError("Veuillez entrer un email valide.");
            return false;
        }
        if (!phoneRegex.test(phoneNumber)) {
            setError("Veuillez entrer un numéro de téléphone valide.");
            return false;
        }
        // Ensure the first guest is always included
        if (guests.length === 0) {
            setGuests([{ firstName, lastName, comment: mainGuestComment, brunch: false }]);
        } else {
            guests[0] = { firstName, lastName, comment: mainGuestComment, brunch: false };
        }
        setError("");
        return true;
    };

    const addNewGuest = () => {
        const lastGuest = guests[guests.length - 1];

        // Prevent adding a new guest if the last one is still empty
        if (!lastGuest || lastGuest.firstName.trim() === "" || lastGuest.lastName.trim() === "") {
            return;
        }

        setGuests([...guests, { firstName: "", lastName: "", comment: "", brunch: false }]);
        setStep(step + 1);
    };


    const handleGuestChange = (index, field, value) => {
        const updatedGuests = [...guests];
        updatedGuests[index][field] = value;
        setGuests(updatedGuests);
    };

    const handleBrunchChange = (index, value) => {
        const updatedGuests = [...guests];
        updatedGuests[index].brunch = value;
        setGuests(updatedGuests);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        // Remove any guest with empty firstName and lastName
        const cleanedGuests = guests.filter(guest => guest.firstName.trim() !== "" && guest.lastName.trim() !== "");

        try {
            await axios.post("https://api.lea-lalaina.com/submit", {
                email,
                phoneNumber,
                guests: cleanedGuests,
            });

            setLoading(false);
            navigate("/gallery");
        } catch (error) {
            setLoading(false);
            setError("Une erreur est survenue lors de l'envoi des données.");
        }
    };


    const isAddGuestDisabled = () => {
        // Ensure the first and last name fields are filled for the current guest
        const currentGuest = guests[step - 2];
        return !currentGuest || !currentGuest.firstName || !currentGuest.lastName;
    };

    return (
        <Container id="RSVPcontainer">
            <Form className="RSVP-form" onSubmit={handleSubmit}>
                {error && <div className="error-message">{error}</div>}

                {/* Step 1: Main Guest Information */}
                {step === 1 && (
                    <>
                        <h1 className="text-center">RSVP</h1>
                        <FloatingLabel label="Email" className="mb-3">
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Téléphone" className="mb-3">
                            <Form.Control
                                type="tel"
                                inputMode="numeric"
                                pattern="^(?:\+33\s?|0)(6|7)\d{8}$"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Nom" className="mb-3">
                            <Form.Control
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Prénom" className="mb-3">
                            <Form.Control
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Commentaire" className="mb-3">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={mainGuestComment}
                                onChange={(e) => setMainGuestComment(e.target.value)}
                            />
                        </FloatingLabel>
                        <Button onClick={() => validateStep1() && setStep(2)} className="mt-2">
                            Suivant
                        </Button>
                    </>
                )}

                {/* Step 2+: Add Guests */}
                {step > 1 && step <= guests.length + 1 && guests[step - 2] && (
                    <>
                        <h1 className="text-center">Ajoutez un invité</h1>
                        <FloatingLabel label="Prénom" className="mb-3">
                            <Form.Control
                                type="text"
                                value={guests[step - 2]?.firstName || ""}
                                onChange={(e) => handleGuestChange(step - 2, "firstName", e.target.value)}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Nom" className="mb-3">
                            <Form.Control
                                type="text"
                                value={guests[step - 2]?.lastName || ""}
                                onChange={(e) => handleGuestChange(step - 2, "lastName", e.target.value)}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Commentaire" className="mb-3">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={guests[step - 2]?.comment || ""}
                                onChange={(e) => handleGuestChange(step - 2, "comment", e.target.value)}
                            />
                        </FloatingLabel>
                        {/* Brunch Checkbox */}
                        <Form.Check
                            type="checkbox"
                            label="Participera au brunch du lendemain"
                            checked={guests[step - 2]?.brunch || false}
                            onChange={(e) => handleBrunchChange(step - 2, e.target.checked)}
                            className="mb-3"
                        />
                        <Button onClick={() => setStep(step - 1)} className="me-2">Précédent</Button>
                        <Button onClick={addNewGuest} id="deac" className="me-2" disabled={isAddGuestDisabled()}>
                            Ajouter un autre invité
                        </Button>
                        <Button onClick={() => setStep(step + 1)}>Suivant</Button>
                    </>
                )}

                {/* Step X+2: Confirmation of Guests */}
                {step === guests.length + 2 && (
                    <>
                        <h2 className="text-center">Confirmation</h2>
                        <h3>Invités:</h3>
                        {guests.map((guest, index) => (
                            <p key={index}>
                                {guest.firstName} {guest.lastName} {guest.comment && `- "${guest.comment}"`}
                                {guest.brunch && <span> - Participera au brunch le lendemain</span>}
                            </p>
                        ))}
                        <Button onClick={() => setStep(guests.length + 1)} className="me-2">Précédent</Button>
                        <Button onClick={() => setStep(1)} className="me-2">Revenir au début</Button>
                        <Button type="submit" disabled={loading}>
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Valider"}
                        </Button>
                    </>
                )}
            </Form>
        </Container>
    );
}

export default MultiStepForm;
