import Home from './components/Home';
// import About from './components/About';
// import Contact from './components/Contact';
import RSVP from './components/rsvp';
import GALLERY from './components/gallery';
import ABOUT from './components/info';


const routes = [
    { path: '/', component: <Home />, exact: true },
    { path: '/about', component: <ABOUT /> },
    { path: '/gallery', component: <GALLERY /> },
    { path: '/rsvp', component: <RSVP /> },
];

export default routes;