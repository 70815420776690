import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import styles from '../styles/NavBar.module.css';

const Logo = "2.svg";

const NavBar = () => {
    const [scrolling, setScrolling] = useState(false);

    // Detect scroll event to change navbar background
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Navbar collapseOnSelect expand="lg" fixed="top" className={`${styles.navbar} ${scrolling ? styles.scrollingNavbar : ''}`}>
            <Container className={styles.navbarContainer}>
                <Navbar.Brand id="brand" as={NavLink} to="/">
                    <img width="100px" height="auto" className="img-responsive" src={Logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link as={Link} to="/#home" className={styles.navLink} eventKey="/#home">
                            Accueil
                        </Nav.Link>
                        <Nav.Link as={Link} to="/about" className={styles.navLink} eventKey="/#about">
                            Information
                        </Nav.Link>
                        <Nav.Link as={Link} to="/gallery" className={styles.navLink} eventKey="/#carousel">
                            Galerie
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/rsvp" className={styles.navLink} eventKey="/#rsvp">
                            RSVP
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
