import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes';
import NavBar from './components/NavBar';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100); // 100ms delay to ensure the page is rendered

    return () => clearTimeout(timer); // Cleanup timeout if component is unmounted or pathname changes
  }, [pathname]);

  return null;
};



function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensure this is inside Router */}
      <NavBar />
      <div>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.component} />
          ))}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
