import React, { useEffect, useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Container, Row, Col, Spinner } from "react-bootstrap";


import "../styles/gallery.css"

// Function to load image dimensions (width and height)
const loadImage = (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            resolve({
                src: img.src,
                width: img.width,
                height: img.height
            });
        };
        img.onerror = (err) => {
            reject(err);
        };
    });
};

export default function GalleryPage() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [photos, setPhotos] = useState([]);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    // Load images from the context and retrieve their dimensions
    useEffect(() => {
        const images = require.context('../img/v2/gallery', true);

        const loadPhotos = async () => {
            const imagePaths = images.keys();
            const loadedPhotos = await Promise.all(
                imagePaths.map((imagePath) => {
                    const imageSrc = images(imagePath);  // Get the image source from the context
                    return loadImage(imageSrc).then((photo) => ({
                        src: photo.src,
                        width: photo.width,
                        height: photo.height,
                    }));
                })
            );
            setPhotos(loadedPhotos);  // Set the loaded photos with dimensions to state
        };

        loadPhotos();  // Load photos when component mounts
    }, []);

    return (
        
        <FadeIn>
            <div className="homeContainer">
                <h1 className="galleryTitle">Gallery</h1>
                {photos.length > 0 ? (
                    <Gallery photos={photos} onClick={openLightbox} />
                ) : (
                    <p>Loading images...</p>
                )}
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map((x) => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </FadeIn>
    );
}
